// src/App.js (updated)
import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  CssBaseline,
  useScrollTrigger,
  Slide,
  Menu,
  MenuItem,
  ThemeProvider,
  createTheme,
  Box,
  Autocomplete,
  TextField,
  Stack,
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
  Grid,
  Divider,
  Backdrop,
  CardContent,
  CardActions,
  Modal,
  Alert,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete'; // Importing the Delete icon
import { ReactComponent as BeybattleParkLogo } from './assets/beybattlepark.svg'; // Importing SVG as a React component
import SignatureCanvas from 'react-signature-canvas'; // Import SignatureCanvas

// Import images
import tysonImg from './assets/tyson.png';
import maxImg from './assets/max.png';

// Define the styles for the waiver modal
const waiverModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90vh',
  overflowY: 'auto',
};

// Your existing data arrays
const beybladeXBlades = [
  "Aero Pegasus",
  "Bear Scratch",
  "Bite Croc",
  "Black Shell",
  "Cobalt Dragoon",
  "Cobalt Drake",
  "Crimson Garuda",
  "Darth Vader",
  "Dragoon Storm",
  "Dran Buster",
  "Dran Dagger",
  "Dran Sword",
  "Dranzer Spiral",
  "Driger Slash",
  "Ghost Circle",
  "Hells Chain",
  "Hells Hammer",
  "Hells Scythe",
  "Impact Drake",
  "Iron Man",
  "Knife Shinobi",
  "Knight Lance",
  "Knight Mail",
  "Knight Shield",
  "Leon Claw",
  "Leon Crest",
  "Lightning L-Drago",
  "Luke Skywalker",
  "Maguro Edge",
  "Mammoth Tusk",
  "Megatron",
  "Moff Gideon",
  "Optimus Primal",
  "Optimus Prime",
  "Phoenix Feather",
  "Phoenix Rudder",
  "Phoenix Wing",
  "Rhino Horn",
  "Roar Tyranno",
  "Samurai Saber",
  "Savage Bear",
  "Shark Edge",
  "Shinobi Knife",
  "Shinobi Shadow",
  "Silver Wolf",
  "Sphinx Cowl",
  "Spider-Man",
  "Starscream",
  "Steel Samurai",
  "Sting Unicorn",
  "Stone Mont Blanc",
  "Storm Pegasis",
  "Strike Hawk",
  "Talon Ptera",
  "Thanos",
  "The Mandalorian",
  "Trypio",
  "Tusk Mammoth",
  "Tyranno Beat",
  "Unicorn Sting",
  "Venom",
  "Very Berry Bomb",
  "Victory Valkyrie",
  "Viper Tail",
  "Weiss Tiger",
  "Whale Wave",
  "Wizard Arrow",
  "Wizard Rod",
  "Wyvern Gale",
  "Yell Kong",
];

const beybladeXRatchets = [
  "0-80",
  "1-60",
  "1-80",
  "2-60",
  "2-70",
  "2-80",
  "3-60",
  "3-70",
  "3-80",
  "3-85",
  "4-60",
  "4-70",
  "4-80",
  "5-60",
  "5-70",
  "5-80",
  "7-60",
  "7-70",
  "9-60",
  "9-70",
  "9-80",
];

const beybladeXBits = [
  "F - Flat",
  "T - Taper",
  "B - Ball",
  "N - Needle",
  "HN - High Needle",
  "LF - Low Flat",
  "P - Point",
  "O - Orb",
  "S - Spike",
  "R - Rush",
  "HT - High Taper",
  "GF - Gear Flat",
  "GB - Gear Ball",
  "GP - Gear Point",
  "GN - Gear Needle",
];

// Page Components
const Home = ({ onNavigate }) => (
  <Container maxWidth="lg" sx={{ p: 4 }}>
    <Grid container spacing={4} justifyContent="center" sx={{ mt: 4 }}>
      {/* Card 1: My Beyblades */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardActionArea onClick={() => onNavigate('My Beyblades')}>
            <CardMedia
              component="img"
              height="200"
              image={tysonImg} // Replace with your image URL
              alt="My Beyblades"
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)',
                padding: '8px 16px',
                borderRadius: '8px',
              }}
            >
              <Typography variant="h6">My Beyblades</Typography>
            </Box>
          </CardActionArea>
        </Card>
      </Grid>

      {/* Card 2: Join Event */}
      <Grid item xs={12} sm={6} md={4}>
        <Card>
          <CardActionArea onClick={() => onNavigate('Join Event')}>
            <CardMedia
              component="img"
              height="200"
              image={maxImg} // Replace with your image URL
              alt="Join Event"
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                color: 'white',
                backgroundColor: 'rgba(0,0,0,0.5)',
                padding: '8px 16px',
                borderRadius: '8px',
              }}
            >
              <Typography variant="h6">Join Event</Typography>
            </Box>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  </Container>
);

const MyBeyblades = () => {
  // State to manage Beyblade collection
  const [collection, setCollection] = useState([]);

  // Form state
  const [name, setName] = useState('');
  const [selectedBlade, setSelectedBlade] = useState(null);
  const [selectedRatchet, setSelectedRatchet] = useState(null);
  const [selectedBit, setSelectedBit] = useState(null);

  // Load collection from localStorage on component mount
  useEffect(() => {
    const storedCollection = localStorage.getItem('beybladeCollection');
    if (storedCollection) {
      setCollection(JSON.parse(storedCollection));
    }
  }, []);

  // Save collection to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('beybladeCollection', JSON.stringify(collection));
  }, [collection]);

  // Handle form submission
  const handleAddToCollection = () => {
    if (!name.trim()) {
      alert('Please enter a name for your Beyblade.');
      return;
    }

    const newBeyblade = {
      id: Date.now(), // Unique identifier
      name: name.trim(),
      blade: selectedBlade || '',
      ratchet: selectedRatchet || '',
      bit: selectedBit || '',
    };

    setCollection([...collection, newBeyblade]);

    // Reset form fields
    setName('');
    setSelectedBlade(null);
    setSelectedRatchet(null);
    setSelectedBit(null);
  };

  // Handle deletion of a Beyblade
  const handleDelete = (id) => {
    const updatedCollection = collection.filter((bey) => bey.id !== id);
    setCollection(updatedCollection);
  };

  return (
    <Container maxWidth="md" sx={{ p: { xs: 2, sm: 4 } }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
        }}
      >
        {/* Table Section */}
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ pb: 4 }} variant="h4" gutterBottom>
            My Beyblades
          </Typography>
          <TableContainer component={Paper}>
            <Table aria-label="Beyblade Collection Table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Blade</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Ratchet</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Bit</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>Actions</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {collection.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No Beyblades in your collection. Add some below!
                    </TableCell>
                  </TableRow>
                ) : (
                  collection.map((bey) => (
                    <TableRow key={bey.id}>
                      <TableCell>{bey.name || '-'}</TableCell>
                      <TableCell>{bey.blade || '-'}</TableCell>
                      <TableCell>{bey.ratchet || '-'}</TableCell>
                      <TableCell>{bey.bit || '-'}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="delete"
                          color="error"
                          onClick={() => handleDelete(bey.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Divider sx={{ mb: 4, mt: 4 }}></Divider>
        </Box>

        <Typography variant="h4" gutterBottom sx={{ pb: 4 }}>
          Add Beyblades
        </Typography>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <TextField
            label="Name (required)"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Autocomplete
            disablePortal
            options={beybladeXBlades}
            value={selectedBlade}
            onChange={(event, newValue) => setSelectedBlade(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose your Blade"
                variant="outlined"
              />
            )}
            fullWidth
          />
          <Autocomplete
            disablePortal
            options={beybladeXRatchets}
            value={selectedRatchet}
            onChange={(event, newValue) => setSelectedRatchet(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose your Ratchet"
                variant="outlined"
              />
            )}
            fullWidth
          />
          <Autocomplete
            disablePortal
            options={beybladeXBits}
            value={selectedBit}
            onChange={(event, newValue) => setSelectedBit(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Choose your Bit" variant="outlined" />
            )}
            fullWidth
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddToCollection}
          >
            Add to My Beyblades
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

// Updated JoinEvent Component with Event Sign-Up Functionality
const JoinEvent = () => {
  // State variables for form fields
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [eventCode, setEventCode] = useState('');

  // State variables for waiver
  const [openWaiver, setOpenWaiver] = useState(false);
  const [signature, setSignature] = useState(null);
  const [waiverAgreed, setWaiverAgreed] = useState(false);

  // State for handling errors and submission success
  const [errors, setErrors] = useState({});
  const [submitSuccess, setSubmitSuccess] = useState(false);

  // Reference to the signature canvas
  const sigCanvas = useRef({});

  // Function to open the waiver modal
  const handleOpenWaiver = () => setOpenWaiver(true);

  // Function to close the waiver modal
  const handleCloseWaiver = () => setOpenWaiver(false);

  // Function to clear the signature
  const clearSignature = () => {
    sigCanvas.current.clear();
  };

  // Function to save the signature
  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      setSignature(dataURL);
      setWaiverAgreed(true);
      handleCloseWaiver();
    } else {
      alert('Please provide a signature.');
    }
  };

  // Form validation
  const validate = () => {
    let temp = {};
    temp.firstName = firstName ? '' : 'First name is required.';
    temp.lastName = lastName ? '' : 'Last name is required.';
    temp.eventCode = eventCode ? '' : 'Event code is required.';
    temp.signature = waiverAgreed ? '' : 'You must agree to the waiver.';
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === '');
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      // Here, you can handle the form submission, e.g., send data to backend
      console.log({
        firstName,
        lastName,
        eventCode,
        signature,
      });
      setSubmitSuccess(true);
      // Reset form fields
      setFirstName('');
      setLastName('');
      setEventCode('');
      setSignature(null);
      setWaiverAgreed(false);
      clearSignature();
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Join Event
      </Typography>
      <Typography>Join an upcoming event by signing up below.</Typography>
  
      <Container maxWidth="sm">
        <Box sx={{ mt: 5 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Event Sign-Up
          </Typography>
          {submitSuccess && (
            <Alert
              severity="success"
              onClose={() => setSubmitSuccess(false)}
              sx={{ mb: 2 }}
            >
              Successfully signed up for the event!
            </Alert>
          )}
          <form onSubmit={handleSubmit}>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              {...(errors.firstName && {
                error: true,
                helperText: errors.firstName,
              })}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              {...(errors.lastName && {
                error: true,
                helperText: errors.lastName,
              })}
            />
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" onClick={handleOpenWaiver}>
                Waiver to Participate
              </Button>
              {errors.signature && (
                <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                  {errors.signature}
                </Typography>
              )}
              {waiverAgreed && signature && (
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1">Signature:</Typography>
                  <img
                    src={signature}
                    alt="User signature"
                    style={{
                      border: '1px solid #000',
                      width: '50%', // Half the original size
                      height: 'auto', // Maintain aspect ratio
                    }}
                  />
                </Box>
              )}
            </Box>
            <TextField
              label="Event Code"
              variant="outlined"
              fullWidth
              required
              margin="normal"
              value={eventCode}
              onChange={(e) => setEventCode(e.target.value)}
              {...(errors.eventCode && {
                error: true,
                helperText: errors.eventCode,
              })}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
            >
              Submit
            </Button>
          </form>
        </Box>
  
        {/* Waiver Modal */}
        <Modal
          open={openWaiver}
          onClose={handleCloseWaiver}
          aria-labelledby="waiver-modal-title"
          aria-describedby="waiver-modal-description"
        >
          <Box sx={waiverModalStyle}>
            <Typography id="waiver-modal-title" variant="h6" component="h2">
              Waiver to Participate
            </Typography>
            <Typography id="waiver-modal-description" sx={{ mt: 2 }}>
              <p>
                Please read the following waiver carefully before participating in the event.
                By signing below, you acknowledge that you have read, understood, and agree to
                the terms and conditions. You agree to hold harmless the event organizers from
                any liability.
              </p>
            </Typography>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                height: '300px', // Set a fixed height
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  border: '1px solid #000',
                  borderRadius: '4px',
                  backgroundColor: '#f5f5f5',
                  overflow: 'hidden',
                  position: 'relative',
                }}
              >
                <SignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: 600,
                    height: 300,
                    className: 'sigCanvas',
                  }}
                  ref={sigCanvas}
                  backgroundColor="#f5f5f5"
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                  }}
                />
              </Box>
              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  onClick={clearSignature}
                  sx={{ mr: 2 }}
                >
                  Clear Signature
                </Button>
                <Button variant="contained" onClick={saveSignature}>
                  Agree and Sign
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Container>
    </Box>
  );
};

// HideOnScroll Component to hide AppBar on scroll
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function App() {
  // State to manage Menu open/close
  const [anchorEl, setAnchorEl] = React.useState(null);

  // State to manage current page
  const [currentPage, setCurrentPage] = React.useState('Home');

  // State for consent
  const [hasConsented, setHasConsented] = useState(false);
  const [showConsentBackdrop, setShowConsentBackdrop] = useState(false); // Renamed for clarity

  // Function to initialize Clarity
  const initializeClarity = () => {
    // Dynamically import Clarity to ensure it's only loaded after consent
    import('@microsoft/clarity').then(({ default: Clarity }) => {
      const projectId = 'p3oz3nqet3';
      Clarity.init(projectId);
      console.log('Clarity initialized');
    }).catch((err) => {
      console.error('Failed to load Clarity:', err);
    });
  };

  // Check for consent on mount
  useEffect(() => {
    const consent = localStorage.getItem('clarityConsent');
    if (consent === 'true') {
      setHasConsented(true);
      initializeClarity();
    } else if (consent === 'false') {
      setHasConsented(false);
    } else {
      // No consent given yet, show Backdrop
      setShowConsentBackdrop(true);
    }
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (page) => {
    setCurrentPage(page);
    handleMenuClose();
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#A2FF1F', // Set primary color
      },
    },
  });

  // Navigation items
  const navItems = ['Home', 'My Beyblades', 'Join Event'];

  // Function to handle navigation from Home component
  const handleNavigate = (page) => {
    setCurrentPage(page);
  };

  // Handle "Accept All" action
  const handleAcceptAll = () => {
    localStorage.setItem('clarityConsent', 'true');
    setHasConsented(true);
    setShowConsentBackdrop(false);
    initializeClarity();
  };

  // Handle "Manage" action
  const handleManage = () => {
    setShowConsentBackdrop(false);
    setCurrentPage('Join Event'); // Redirect to 'Join Event' for now
    // You can implement a preferences modal or page here
  };

  // Function to render the selected page
  const renderPage = () => {
    switch (currentPage) {
      case 'Home':
        return <Home onNavigate={handleNavigate} />;
      case 'My Beyblades':
        return <MyBeyblades />;
      case 'Join Event':
        return <JoinEvent />;
      default:
        return <Home onNavigate={handleNavigate} />;
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <HideOnScroll>
        <AppBar>
          <Toolbar>
            {/* SVG Logo */}
            <BeybattleParkLogo
              style={{ width: 40, height: 40, marginRight: 16 }}
              aria-label="Beybattle Park Logo"
            />

            {/* Current Page Title */}
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {currentPage}
            </Typography>

            {/* Menu IconButton */}
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleMenuOpen}
            >
              <MenuIcon />
            </IconButton>

            {/* Menu Component */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {navItems.map((item) => (
                <MenuItem
                  key={item}
                  selected={currentPage === item}
                  onClick={() => handleMenuItemClick(item)}
                >
                  {item}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar /> {/* To offset the content below AppBar */}
      <Box component="main">{renderPage()}</Box>

      {/* Consent Backdrop */}
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
        }}
        open={showConsentBackdrop}
      >
        <Card sx={{ maxWidth: 400, bgcolor: 'background.paper', p: 3 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Cookie Consent
            </Typography>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              We value your privacy
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              We use optional cookies to improve your experience. By clicking "Accept All",
              you consent to our use of cookies. You can manage your preferences by clicking
              "Manage".
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleAcceptAll}>
              Accept All
            </Button>
            <Button variant="outlined" color="primary" onClick={handleManage}>
              Manage
            </Button>
          </CardActions>
        </Card>
      </Backdrop>
    </ThemeProvider>
  );
}

export default App;
